body {
  background: white;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

section {
  margin: 12px 0;
}
